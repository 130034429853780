import NextLink from "next/link";
import { Text, Button } from "@chakra-ui/react";
import ErrorPage from "components/Shared/ErrorPage";

function NotFoundPage() {
  return (
    <ErrorPage heading="404" subheading="Oops! Page not found!">
      <Text mt={4} fontSize="xl">
        We can’t find a page for this URL. Sorry about that!
      </Text>
      <NextLink href="/" passHref legacyBehavior>
        <Button as="a" mt={14} colorScheme="lightPinkButton" size="lg">
          Home
        </Button>
      </NextLink>
    </ErrorPage>
  );
}

export default NotFoundPage;

NotFoundPage.allowUnauthenticated = true;
